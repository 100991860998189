export const green = "rgb(39,71,26)";
export const color1 = "rgb(153,115,103)";
export const blushPink = "rgb(222,168,150)";
export const green2 = "rgb(105,164,149)";
export const skyBlue = "rgb(142,209,102)";
export const lightGreen = "rgb(182,212,186)";
export const lightBlue = "rgb(176,213,216)";
export const cream = "rgb(254,254,242)";
export const turkey = "rgb(155,209,193)";
export const lightGreen2 = "rgb(197,218,193)";
export const ocean = "rgb(154,209,191)";


